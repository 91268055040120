---
label: Ejercicio 1
prev: ../
next: ./true-code
---

# Ejercicio 1

El objetivo de este ejercicio sólo es "ver la pinta que tiene" el *source* -
el código. Ver cómo escribimos instrucciones para que una máquina presente la
información de una manera concreta.

No vamos a "programar" nada, simplemente crear un contenido y ver la pinta que
tiene. Importante no "asustarse" por ver código escrito o pensar en que es
necesario saber escribirlo para hacer *webs*, pero sí es importante conocer
que existe y es lo que les da forma y contenido.

- Texto de rondadores de mi alma
- Bloc de notas
- Navegador web

Vamos a hacerlo lo más simple posible y hacerlo con un único archivo, aunque
no sea la forma más correcta ni práctica de hacerlo es la mejor para empezar.

El ejercicio en sí consistirá principalmente en ver HTML y CSS.

1. Montar un layout HTML básico en plan "Hola rondadores!"
   1. Primero creamos un archivo en cualquier sitio. Lo llamaremos
     `rondadores.html`. Lo abrimos y vemos que se abre el navegador, con un
     maravilloso lienzo en blanco
   2. Metemos algo de código con la estructura básica de un documento HTML5
      moderno

      ```html
      <!doctype html>
      <html>
        <head>
        </head>
        <body>
        </body>
      </html>
      ````

      Vemos que sigue en blanco. Empezamos por un `Hola rondadores`, y un
      `<title>Rondadores</title>`.

2. A partir de ahí vamos a improvisar, con una guía mínima:

   - Usar la letra de la canción para cargar contenido y ver un poco qué son
     las etiquetas
   - Maquetar el poema (sin tocar nada de estilos, loliño)



   - Vamos a usar una IA para crear algo de artwork y darle un toque.
     Aquí veremos el power del CSS.
   - Podemos buscar una fuente y pincharla, para ver el poder del hipermedia

Una vez hecho
esto, introduzco demostración práctica de la pinta que tiene javascript y
para qué puede usarse.

---

Conclusiones después del ejercicio.

- Entender que **cualquier web** funciona igual que lo que acabamos de hacer

---

Podemos ver [sitios html only](https://devpractical.com/html-only-websites/)
desde ese artículo, pasando por el [x.com](http://x.com/) de Elon Musk o el
mágico [motherfuckingwebsite.com](http://motherfuckingwebsite.com/), que aún
usando un lenguaje muy hater habla de algo esencial:

> El objetivo es comunicar y que la información sea accesible.

Del motherfuckingwebsite iremos a su [herramienta gratuita][http://txti.es]
a publicar nuestro ejercicio. Aprovecharemos para entender que la única función
de internet se *mover el source* de las __webs__ de cualquier parte del
mundo a cualquier otra, de cualquier persona a cualquier otra.


ierto es que vamos a querer darle un poco de *punch* a nuestras webs, y no
> haremos mucho caso de tanto evangelista, pero está bien recordar la esencia.

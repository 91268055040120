---
label: Sesion 1
description: Comenzamos
prev: ../
next: ./intro
---

# Sesión 1

Empezaremos por conceptos básicos antes de ponernos a crear algo, pero la idea
es hablar lo mínimo de conceptos y llegar lo más rápido posile al primer
ejercicio.

export const connect = {
  orig: new URL('earth-people.jpg', import.meta.url),
  webp: new URL('earth-people.jpg?as=avif&width=400', import.meta.url),
  avif: new URL('earth-people.jpg?as=webp&width=400', import.meta.url)
}

<Image {...connect} alt='Earth connecting People' width={400} height={400} />

Los objetivos de esta sesión son:

* Entender para qué se hace una web, y desde donde construír una.

  > Suena a _flipadura de zumbados_, pero es importante

* Ver en la práctica cómo se hace una web, a nivel muy elemental

  > Relax! No vamos hacer nada complicado

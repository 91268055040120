---
label: Bienvenida
next: /rondadores/
---

# Bienvenida al dojo

export const dojo = {
  orig: new URL('dojo.jpg', import.meta.url),
  webp: new URL('dojo.jpg?as=avif&width=800', import.meta.url),
  avif: new URL('dojo.jpg?as=webp&width=800', import.meta.url)
}

<Image {...dojo} alt='Artwork dojo' width={800} height={800} />

Es un proyecto que acaba de nacer y estoy usando como apoyo para enseñar y
también para aprender.

Me sirve para desarrollar el contenido de forma rápida y cómoda, y también para
presentarlo.

En el futuro, quizás crezca.

[Empezar](/rondadores/)

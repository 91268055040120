import React from 'react'
import { createRoot } from 'react-dom/client'

import Root from './Root'

if (!global._root) {
  global._root = createRoot(document.getElementById('app'))
}

global._root.render(<Root />)

import React from 'react'
import PropTypes from 'prop-types'

const viewItem = PropTypes.shape({
  id: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    title: PropTypes.string,
    prev: PropTypes.string,
    next: PropTypes.string
  }),
  Component: PropTypes.elementType.isRequired
})

const viewTree = PropTypes.exact({
  id: PropTypes.string.isRequired,
  tree: (...args) => PropTypes.arrayOf(
    PropTypes.oneOfType([viewItem, viewTree])
  ).isRequired(...args)
})

export const viewIndex = PropTypes.arrayOf(
  PropTypes.oneOfType([viewItem, viewTree])
)

// Reduces a viewIndex flattening it as one-dimension array
export const viewReducer = (list, view) => view.tree
  ? view.tree.reduce(viewReducer, list)
  : list.concat(view)

/**
 * Renders a navigation menu with nested levels according to viewIndex
 */
export function List ({ index, ...props }) {
  return (
    <ul {...props}>
      {index.map((view) => (
        <li key={view.id}>
          <a href={view.id || '?'}>
            {(({ label, description }) => (
              label || description || <code>{view.id}</code>
            ))((view.tree ? view.tree[0] : view).meta || {})}
          </a>
          {Array.isArray(view.tree) && (
            <List index={view.tree.slice(1)} />
          )}
        </li>
      ))}
    </ul>
  )
}

List.propTypes = {
  index: viewIndex.isRequired
}

/**
 * Renders MDXContent defined within a document view
 */
export function View ({ view, ...props }) {
  return (
    <article {...props}>
      <view.Component components={View.components} />
    </article>
  )
}

View.propTypes = {
  view: viewItem.isRequired
}

View.components = {
  Image: ({ orig, webp, avif, ...etc }) => (
    <picture
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <source srcSet={avif} type='image/avif' />
      <source srcSet={webp} type='image/webp' />
      <img src={orig} {...etc} style={{ maxWidth: '100%' }} />
    </picture>
  ),
  mermaid: (props) => {
    const ref = React.useRef()
    React.useEffect(() => { window.mermaid.init() }, [props.chart, ref])
    return <div ref={ref} className='mermaid'>{props.chart}</div>
  },
  a: ({ href, ...props }) => (
    <a
      href={href}
      {...(
        href.slice(4) === 'http'
          ? { rel: 'noreferrer', target: '_blank' }
          : {}
      )}
      {...props}
    />
  )
}

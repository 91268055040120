---
label: Tecnologías
prev: ./cualquiera
next: ./ejercicio-1/
---

# Tecnologías

Existe una tendencia a sobrecomplicar la "parte técnica", creemos que es
difícil o complejo cuando en la práctica es simple. Por eso funciona

[HTML]: https://es.wikipedia.org/wiki/HTML
[CSS]: https://es.wikipedia.org/wiki/CSS
[JS]: https://es.wikipedia.org/wiki/JavaScript

[lenguaje de marcado]: https://es.wikipedia.org/wiki/Lenguaje_de_marcado

- La tríada de lenguajes básicos en la *web* - [HTML] [CSS] y [JS] - serán el
  punto de partida para el ejercicio práctico que sigue. Vamos a montar una web
  "a pelo", sin nada más que un bloc de notas, como hacían antaño y como muchas
  veces nos enseñan.

  A efectos prácticos - y técnicamente hablando - ni HTML ni CSS son lenguajes
  de programación. Javascript sí.

  - HTML es un [lenguaje de marcado][], se denomina así porque se utilizan
    etiquetas (*tags*) para marcar partes del texto y darle estructura. Lo
    veremos en breve en el primer ejercicio
  - CSS es un lenguaje de diseño gráfico, diseñado en sus orígenes para dar
    control sobre la forma en que se presenta una web en un navegador.

  Importancia en destacar que a día de hoy muchas herramientas nos facilitan
  el trabajo engorroso de escribirlo todo manualmente, así como la necesidad
  de saber que están ahí y son la base fundamental de TODO - siendo TODO la
  web obviamente.

  > Para conducir no es necesario que sepamos construír un motor, pero para
  > tener un coche es importante saber que hay que hecharle combustible,
  > cambiar el aceite, ...

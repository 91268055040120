---
label: Qué es la web?
prev: ./intro
next: ./lenguajes
---

# Qué es _la web_?

[WWW]: https://es.wikipedia.org/wiki/World_Wide_Web

Otra confusión habitual es **Internet** con la [_World Wide Web_][WWW].

> **La web** es un sistema que funciona a través de Internet por el que se
> puede **comunica información** utilizando **Hipertexto**

```mermaid
graph TB;
    sq[Square shape] --> ci((Circle shape));
```

## Hiperqué?

[historia del www]: https://es.wikipedia.org/wiki/Historia_de_la_World_Wide_Web
[hipermedia]: https://es.wikipedia.org/wiki/Hipermedia
[hipertexto]: https://es.wikipedia.org/wiki/Hipertexto
[hiperenlace]: https://es.wikipedia.org/wiki/Hiperenlace

> Orígenes del [WWW] como herramienta para [hipermedia], la importancia de
> enlazar - *unir para los zz :D* - la __información__, comunicarla, y poder
> navegar por ella. Tampoco hay que desarrollarlo mucho, sólo comentar su
> *para qué* con brevedad. La función del [hiperenlace]

Para esto vamos al [artículo sobre la *world wide web*][WWW] y buscaremos
la cita de Arthur C. Clarke de mayo de 1970, en el segundo párrafo de la
sección historia.

> "llevarán el conocimiento acumulado del mundo a sus manos"

  También vamos a ver la [primera página web de la historia](http://info.cern.ch/hypertext/WWW/TheProject.html),
  pasando primero por [su casa: info.cern.ch](http://info.cern.ch)

- El papel del "navegador web" quizá se pueda comentar brevemente.

- La importancia del HTTP(s) es probable que quede mejor en segundo plano, a
  efectos prácticos es una forma de comunicar pero para gente poco techie es
  dar detalles.

> HTTP significa _HiperText Transfer Protocol_, antaño a los frikis les
> gustaban los nombres del palo _Disparador de Rábanos Fritos_, ahora
> son más de nombres _cool_ como _Rabaneitor_, _google_, _facebook_, ...

Dejando [historia del www] y demás vamos a mantenerlo simple!


Ok, pues [vamos a la wikipedia y lo vemos][WWW]. Es broma, vamos a buscar una
forma más simple de concretar qué es

  De todos modos, sí es importante mencionar que el
  *Hipertext Transfer Protocol* aunque suene a cosa técnica compleja es la
  forma (principal) en que se comunica el mundo a través de la red.

  A todo esto, podemos hablar del [hipertexto]. O no, quizá es mejor dejarse
  de tanta introducción?

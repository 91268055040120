/**
 * Parcel allows this non-standard "glob import", easing the discover of
 * documents to make an index
 *
 * Still not working good with HMR:
 * - adding documents needs a clean restart (delete .parcel-cache)
 * - refreshes too much
 */
import * as tree from '../doc/**/*.mdx'

const dumpView = (id = '/', {
  default: Component,
  meta = null,
  ...etc
}) => ({ id, meta, Component, ...etc })

const dumpIndex = (index, prefix = '') => Object.entries(index).map(
  ([k, v]) => v.default
    ? dumpView(`${prefix}${k !== 'index' ? `/${k}` : '/'}`, v)
    : { id: `${prefix}/${k}/`, tree: dumpIndex(v, `${prefix}/${k}`) }
)

export default dumpIndex(tree)

import React from 'react'

import { viewIndex, viewReducer, View, List } from './View'

const headButton = {
  padding: '.2em',
  color: 'khaki',
  textDecoration: 'none',
  lineHeight: '1.2'
}

export default function App ({ index }) {
  const views = React.useMemo(() => {
    return index.reduce(viewReducer, [])
  }, [])

  const [uri, setUri] = React.useState(window.location.pathname)

  const view = React.useMemo(() => {
    return views.find(item => item.id === uri)
  }, [uri])

  React.useEffect(() => {
    const links = document.querySelectorAll('a[href]:not([target])')
    const pushListener = (event) => {
      event.preventDefault()

      let { target } = event
      while (!target.href) {
        target = target.parentElement
        if (!target) {
          return console.warn('No se encontró el enlace', event)
        }
      }

      if (!target.href) {
        return console.warn('Imposible clicar:', { event, target })
      }

      const uri = new URL(target.href).pathname
      window.history.pushState({ uri }, '', uri)
      setUri(uri)
    }
    const popListener = (event) => {
      console.log(event)
      const { uri } = event.state || {}

      if (!uri) {
        return console.warn('Imposible ir atrás:', { event, uri })
      }

      setUri(uri)
    }

    window.addEventListener('popstate', popListener)

    links.forEach($a => $a.addEventListener('click', pushListener))

    return () => {
      links.forEach($a => $a.removeEventListener('click', pushListener))
      window.removeEventListener('popstate', popListener)
    }
  }, [setUri])

  // console.log(index)

  return (
    <>
      <header
        style={{
          height: '5vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          backgroundColor: 'forestgreen',
          fontSize: '1.5em'
        }}
      >
        <a href={view?.meta?.prev} style={headButton}>⇜</a>
        <a href='/' target='_self' style={headButton}>⌂</a>
        <a href={view?.meta?.next} style={headButton}>⇝</a>
      </header>
      <main
        style={{
          width: '100vw',
          boxSizing: 'border-box',
          overflowY: 'auto',
          display: 'flex'
        }}
      >
        {view && (
          <View
            view={view}
            style={{
              margin: '0 auto',
              maxWidth: '40em',
              height: '95vh',
              overflowY: 'auto'
            }}
          />
        )}
        <aside
          style={{
            marginLeft: view ? undefined : 'auto',
            marginRight: 'auto',
            padding: '0 2rem',
            display: 'flex',
            flexFlow: 'column nowrap'
          }}
        >
          <h1
            style={{ marginTop: view ? undefined : 'auto' }}
          >
            Dojo!
          </h1>
          <List
            index={index}
            style={{
              flexGrow: view ? 1 : 0,
              marginBottom: view ? undefined : 'auto'
            }}
          />
          {!view && (
            <details>
              <summary>Inspect</summary>
              uri is "{uri}"
              <details>
                <summary>Index</summary>
                <pre>{JSON.stringify(index, null, 4)}</pre>
              </details>
              <details>
                <summary>Views</summary>
                <pre>{JSON.stringify(views, null, 4)}</pre>
              </details>
            </details>
          )}
          <footer>
            <p
              style={{
                fontSize: '.8em',
                textAlign: 'center',
                lineHeight: 1.4
              }}
            >
              © {new Date().getFullYear()}
              {' '}
              <a
                rel='noreferrer'
                href='https://blog.lorenzogrv.tech'
                target='_blank'
              >
                Lorenzo García Rivera
              </a>
              {' Licencia '}
              <a
                rel='noreferrer'
                href='https://creativecommons.org/licenses/by-nc-sa/4.0/deed.es'
                target='_blank'
              >
                CC BY SA 4.0
              </a>
            </p>
          </footer>
        </aside>
      </main>
    </> // *MDXProvider>*/
  )
}

App.propTypes = {
  index: viewIndex.isRequired
}

const _temp0 = require("../index.mdx");
const _temp1 = require("../rondadores/index.mdx");
const _temp2 = require("../rondadores/sesion-1/cualquiera.mdx");
const _temp3 = require("../rondadores/sesion-1/index.mdx");
const _temp4 = require("../rondadores/sesion-1/intro.mdx");
const _temp5 = require("../rondadores/sesion-1/lenguajes.mdx");
const _temp6 = require("../rondadores/sesion-1/ejercicio-1/index.mdx");
const _temp7 = require("../rondadores/sesion-1/ejercicio-1/true-code.mdx");


const _temp8 = require("../rondadores/sesion-2/index.mdx");


module.exports = {
  "index": _temp0,
  "rondadores":   {
    "index": _temp1,
    "sesion-1":     {
      "cualquiera": _temp2,
      "index": _temp3,
      "intro": _temp4,
      "lenguajes": _temp5,
      "ejercicio-1":       {
        "index": _temp6,
        "true-code": _temp7
      }
    },
    "sesion-2":     {
      "index": _temp8
    }
  }
}
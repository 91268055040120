---
label: Qué es una web?
prev: ./
next: ./cualquiera
---

# Qué es _una web_ en realidad?

[sitio web]: https://es.wikipedia.org/wiki/Sitio_web
[página web]: https://es.wikipedia.org/wiki/P%C3%A1gina_web
[aplicación web]: https://es.wikipedia.org/wiki/Aplicaci%C3%B3n_web

Viste que el título dice **web**, y nó ~~página web~~? Es común hablar de
_páginas web_ y tener una visión superficial sobre ellas:

- La gente suele confundir un [sitio web] con una [página web], o incluso con
  una [aplicación web]. Lo mágico es que no se necesita conocer la diferencia
  para usar una cosa u otra. Ahí reside uno de sus poderes.

- A día de hoy la mayoría de _sitios web_ en realidad son aplicaciones, también
  se les llama _sitios web dinámicos_. Un sitio _wordpress_, por ejemplo, es
  una _aplicación web_.

## Sitios, páginas, aplicaciones... what?

Para no entrar en mucho detalle técnicos imaginemos una [página web] como un
archivo, y un [sitio web] como una _"carpeta"_ con páginas.

> La página es **una unidad**, y el sitio **un conjunto**

Una [aplicación web] es algo más complejo - aunque basado en los mismos
principios.

> De hecho, ni siquiera vamos a comentar las diferencias

## Simplemente webs

Un truco que uso es hablar simplemente de **webs** o de **una web**. De esta
forma cualquier persona con poco conocimiento técnico, un cliente por ejemplo,
puede entendernos con facilidad.

> El término **web** se refiere a la _World Wide Web_: **la web**

Ahora hablaremos de **la web**, pero quedémonos con una cosa muy básica:

> A efectos prácticos cualquier web son archivos corrientes guardados en un
> ordenador **cualquiera**

<details>
  <summary>Y con _cualquiera_, me refiero a uno como este:</summary>
  [
  ![First web server](https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/First_Web_Server.jpg/1200px-First_Web_Server.jpg)
  ](https://commons.wikimedia.org/wiki/File:First_Web_Server.jpg#/media/Archivo:First_Web_Server.jpg)
  
  > El primer servidor web [Wikipedia - CC BY-SA 3.0](http://creativecommons.org/licenses/by-sa/3.0/)
</details>

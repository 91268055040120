---
label: Sesion 2
description: Seguimos
next: ./ejercicio-1
---

# Sesión 2

En esta sesión voy a improvisar mucho mas. Toda mi preparación consiste en:

* Instalar vscode
* Instalar la extensión de lenguaje para poder explicar cómo se hace y a la vez
* ver lo mismo que ella
* Instalar la extensión vslive share para compartir el screen de edición

> Nada más!

Los objetivos de esta sesión son:

* Afianzar conceptos de la primera sesión
* Jugar en mayor profundidad con el CSS, hasta llegar a maquetar rondadores
  con un aspecto similar al original

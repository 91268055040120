---
label: Ejercicio 1
prev: ./
---

# Código de la sesión

A continuación figura el código de la primera sesión que hicimos R. & me día
22 de Septiembre de 2022

```html
<!doctype html>
<html>
  <head>
     <meta charset="UTF-8" />
     <title>Rondadores</title>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
      href="https://fonts.googleapis.com/css2?family=Nabla&family=Roboto:wght@500&display=swap"
      rel="stylesheet"> 
     <style>
       body {
         font-family: 'Roboto', sans-serif;
         background-color: #000;
       }

       p {
         color: green;
       }

       h1 {
         color: coral;
         font-family: 'Nabla', cursive;
         font-size: 10em;
       }
       h2 {
         color: snow;
       }
       ul {
         background-image: url("https://picsum.photos/200/300");
         background-position: center center;
         background-repeat: no-repeat;
         background-size: cover;
         height: 100vh;
       }
       textarea {
         display: block;
       }
     </style>
     <link rel="preconnect" href="https://fonts.googleapis.com">
     <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
     <link
       href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap"
       rel="stylesheet"> 
  </head>
  <body>
    <a
      href="https://dojo.lorenzogrv.tech/"
      target="blank"
    >
      Esto es un enlace
    </a>
    <textarea>
    </textarea>
    <input type="submit"/>
    <img alt="imagen" src="https://picsum.photos/200/300" />
    <h1>Rondadores</h1>
    <h2>Rondadores 2</h2>
    <p
      style="background-color: red;"
    >
      Cuando los zumbaos vienen
      plenos de humildad
      es señal que han hecho
      un Master Ralaya.
    </p>
    <p> 
      Cuando los zumbaos vienen
      llenos de verdad,
      es señal que han  hecho
      coaching en la Finca.
    </p>
    <p> 
      <strong>
        <em>
          Rondadores de mi alma
          Y mi corazón
          No voy sola, no voy sola
          No voy sola no,
          No voy sola, no voy sola
          Ahora sé que soy…
        </em>
      </strong>
    </p>
    <p> 
      La confianza de Rochi
      la calma de Aser,
      la paciencia de Alfonso
      el brillo de Isabelle.
    </p>
    <p> 
      Nataly la dulzura
      Chelo el corazón,
      Antón pura ternura
      Gerardo rendición.
    </p>
    <p>
      <i>
        <strong>Rondadores de mi alma…</strong>
      </i>
    </p>
    <p> 
      La admiración de Jorge
      y bondad de Rubén,
      las poderosas Dolo
      y Maria José.
    </p>
    <p> 
      Generosa Rocío
      Conchi determinación,
      Sabiduría
      Patri pura unión.
    </p>
    <p> 
      <strong>Rondadores de mi alma…</strong>
    </p>
    <p> 
      La certeza de Betty
      y presencia de Montse,
      honestidad de Marta
      alegría de Sele.
    </p>
    <p> 
      Sonia y su coherencia
      Viki y su ¡POWER!
      Valiente MariTere
      Luis enorme Ser.
    </p>
    <p> 
      <strong>Rondadores de mi alma…</strong>
    </p>
    <p> 
      Ana PUEDE y se ama
      Dani emana paz
      para santo Lorenzo
      Candy libertad.
    </p>
    <p> 
      Cada cosa que canto
      se puede aplicar,
      a cada UN de nosotros
      esa es la verdad.
    </p>
    <p> 
      <strong>
        Rondadores de mi alma
        Y mi corazón
        No vais solos, no vais solos
        No vais solos no
        No vais solos, no vais solos
        Que vais con mi amor 
        No vais solos, no vais solos…
        …que somos unión.
        …que somos amor.
      </strong>
    </p>
    <p> 
      …GRACIAS POR TODO!!! 
    </p>
    <p> 
      ♥️
    </p>
  </body>
</html>
```

---
label: Rondadores
description: Bienvenida al curso introductorio
prev: ../
next: ./sesion-1/
---

# Rondadores

export const rondadores = {
  orig: new URL('rondadores.jpg', import.meta.url),
  webp: new URL('rondadores.jpg?as=avif&width=500', import.meta.url),
  avif: new URL('rondadores.jpg?as=webp&width=500', import.meta.url)
}

<Image {...rondadores} alt='Rondadores' width={500} height={500} />

> Por algún motivo, quixen darlle nome propio a isto. Ao principio chameille
> *rondadora* porque se trata de ensinar, pero mentras escribo dinme conta de
> que eu tamén vou aprender durante o proceso. Somos rondadores \<3
>
> @lorenzogrv, 17/09/2022
